<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("RECLAMATIONS.RECLAMATIONS_LIST") }}</h3>
          </div>

          <div class="col-6 text-right">
            <!--<base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openReclamationCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_RECLAMATIONS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("RECLAMATIONS.ADD_RECLAMATION")
              }}</span>
            </base-button>-->

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_RECLAMATIONS)"
              :objectType="'reclamations'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <reclamation-list-table
        @onViewReclamation="openReclamationViewModal"
        @onEditReclamation="openReclamationEditModal"
        @onDeleteReclamation="deleteReclamation"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewReclamationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewReclamationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RECLAMATION_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RECLAMATIONS.VIEW_RECLAMATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openReclamation"
                :objectType="'reclamations'"
                :objectId="openReclamation.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeReclamationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-reclamation-component
              v-if="openReclamation"
              :reclamationId="openReclamation.id"
              @onEditReclamation="openReclamationEditModal"
              @onDeleteReclamation="deleteReclamation"
              @onCompleteReclamation="completeReclamation"
              @onCancelReclamation="cancelReclamation"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditReclamationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditReclamationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RECLAMATION'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RECLAMATIONS.EDIT_RECLAMATION") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openReclamationViewModal(openReclamation)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeReclamationModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-reclamation-page
              v-if="openReclamation"
              :reclamationId="openReclamation.id"
              @onViewReclamation="openReclamationViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddReclamationModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddReclamationModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RECLAMATION'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("RECLAMATIONS.ADD_RECLAMATION") }}
            </h1>
            <button class="close" @click="closeReclamationModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-reclamation-page
              @onViewReclamation="openReclamationViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ReclamationListTable from "./partials/ReclamationListTable.vue";
import EditReclamationPage from "./components/EditReclamationComponent.vue";
import AddReclamationPage from "./components/AddReclamationComponent.vue";
import ViewReclamationComponent from "./components/ViewReclamationComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ReclamationListTable,
    NotificationSubscription,
    EditReclamationPage,
    AddReclamationPage,
    ViewReclamationComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const reclamationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewReclamationModalOpened = false;
    let isEditReclamationModalOpened = false;
    let isAddReclamationModalOpened = false;
    let openReclamation = null;
    if (reclamationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewReclamationModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditReclamationModalOpened = true;
      }
      openReclamation = { id: reclamationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddReclamationModalOpened = true;
    }
    return {
      isViewReclamationModalOpened: isViewReclamationModalOpened,
      isEditReclamationModalOpened: isEditReclamationModalOpened,
      isAddReclamationModalOpened: isAddReclamationModalOpened,
      openReclamation: openReclamation,
      renderKey: 1,
    };
  },

  methods: {
    openReclamationCreateModal() {
      this.closeReclamationModal();
      this.isAddReclamationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Reclamations",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openReclamationViewModal(reclamation, reRender = false) {
      this.closeReclamationModal();
      this.openReclamation = reclamation;
      this.isViewReclamationModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Reclamations",
          query: { id: this.openReclamation.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openReclamationEditModal(reclamation) {
      this.closeReclamationModal();
      this.openReclamation = reclamation;
      this.isEditReclamationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Reclamations",
          query: { id: this.openReclamation.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeReclamationModal() {
      this.isAddReclamationModalOpened = false;
      this.isViewReclamationModalOpened = false;
      this.isEditReclamationModalOpened = false;
      this.openReclamation = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Reclamations",
          query: {},
        }).href
      );
    },
    async completeReclamation(reclamation) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("RECLAMATIONS.COMPLETE_RECLAMATION_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("reclamations/complete", reclamation.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("RECLAMATIONS.RECLAMATION_COMPLETED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async cancelReclamation(reclamation) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("RECLAMATIONS.CANCEL_RECLAMATION_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("reclamations/cancel", reclamation.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("RECLAMATIONS.RECLAMATION_CANCELED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async deleteReclamation(reclamation) {
      const confirmation = await swal.fire({
        text: this.$t("RECLAMATIONS.DELETE_THIS_RECLAMATION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("reclamations/destroy", reclamation.id);
          this.renderKey++;
          this.closeReclamationModal();
          this.$notify({
            type: "success",
            message: this.$t("RECLAMATIONS.RECLAMATION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
