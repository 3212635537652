<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!reclamation">
      <span class="loader"></span>
    </span>
    <reclamation-form
      v-if="reclamation"
      :loading="loading"
      :reclamationData="reclamation"
      :formErrors="formErrors"
      @reclamationSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import ReclamationForm from "../partials/ReclamationForm.vue";

export default {
  layout: "DashboardLayout",

  components: { ReclamationForm },

  mixins: [alertLeave],

  props: {
    reclamationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      reclamation: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("reclamations/get", this.reclamationId);
        this.reclamation = this.$store.getters["reclamations/reclamation"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(reclamation) {
      this.loading = true;
      let reclamationData = cloneDeep(reclamation);
      reclamationData.relationshipNames = [
        "transaction",
      ];

      try {
        await this.$store.dispatch("reclamations/update", reclamationData);
        this.$notify({
          type: "success",
          message: this.$t("RECLAMATIONS.RECLAMATION_UPDATED"),
        });
        const reclamation = this.$store.getters["reclamations/reclamation"];
        this.$emit("onViewReclamation", reclamation, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
