<template>
      <form
        class="add-form"
        ref="profile_form"
        @submit.prevent="handleSubmit"
        @keydown.enter.prevent="() => {}"
      >
        <div class="form-wrapper full">
          <base-input
            :label="`${$t('RECLAMATIONS.MOTIF')} (*)`"
            :placeholder="$t('RECLAMATIONS.MOTIF')"
          >
            <el-select
              :clearable="true"
              class="select-primary pagination-select"
              :placeholder="$t('RECLAMATIONS.MOTIF')"
              v-model="reclamation.motif_end"
              @change="onFormChanged"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in RECLAMATIONS_MOTIFS_ENDS"
                :key="key"
                :label="$t(`RECLAMATIONS.MOTIF_END_${item}_SELECT`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.motif_end" />
        </div>
        <div class="form-wrapper full">
          <base-input
            :label="$t('RECLAMATIONS.EXCERPT')"
            :placeholder="$t('RECLAMATIONS.EXCERPT')"
          >
            <html-editor v-model="reclamation.description_end">
            </html-editor>
          </base-input>
          <validation-error :errors="apiValidationErrors.description_end" />
        </div>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            {{ $t("COMMON.VALIDATE") }}
          </base-button>
        </div>
      </form>
</template>
<script>
import Vue from "vue";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import "flatpickr/dist/flatpickr.css";
import { RECLAMATIONS_MOTIFS_ENDS } from "@/constants/reclamations";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    HtmlEditor,
  },

  mixins: [formMixin],

  props: ["reclamationData", "formErrors", "loading"],

  data() {
    let reclamationData = cloneDeep(this.reclamationData);
    reclamationData = this.$fillUserOrganizationData(reclamationData);
    return {
      reclamation: reclamationData,
      RECLAMATIONS_MOTIFS_ENDS,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {
    this.reclamation.description_end = "";
  },

  mounted() {},

  methods: {
    async handleSubmit() {
      let reclamationData = cloneDeep(this.reclamation);
      reclamationData = this.$fillUserOrganizationData(reclamationData);
      this.$emit("reclamationSubmitted", reclamationData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    reclamationData(reclamationData) {
      if (reclamationData) {
        this.reclamation = {
          ...this.reclamation,
          ...cloneDeep(reclamationData),
        };
      }
    },
  },
};
</script>
