export default {
  type: "comments",
  comment: "",
  attachements: null,
  relationshipNames: ["organization", "object", "replyTo"],
  organization: {
    type: "organizations",
    id: null,
  },
  object: {
    type: null,
    id: null,
  },
  replyTo: null,
};
