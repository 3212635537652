<template>
  <div class="estimate-comments-item-line">
    <div class="estimate-comments-item-line-header">
      <ul>
        <li class="icon">
          <i class="fal fa-user"></i>
        </li>
        <li class="name"><user :user="comment.creator" /></li>
        <li class="post-time">({{ $tzTimeAgo(comment.created_at) }})</li>
      </ul>
    </div>
    <div class="estimate-comments-item-line-content scrollspy-target">
      <a
        class="bg-lighter mt-3 mb-2 p-3 border-left-4px text-sm d-block"
        v-if="!!comment.replyTo"
        :href="`#scrollspy-${comment.replyTo?.id}`"
        v-html="comment.replyTo.short_comment"
      />
      <div v-html="comment.comment" class="ml-0" />
      <comment-view-attachements :comment="comment" />
      <button @click.prevent="reply" v-if="!hiddenReply">
        <i class="far fa-reply"></i>
      </button>
    </div>
  </div>
</template>

<script>
import User from "@/components/User.vue";
import CommentViewAttachements from "./CommentViewAttachements.vue";

export default {
  name: "comments-item-card",

  components: { 
    User,
    CommentViewAttachements,
  },

  props: {
    comment: {
      type: Object,
      required: true,
    },
    hiddenReply: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  created(){},

  methods: {
    reply() {
      this.$emit("onReply", this.comment);
    },
  },
};
</script>

<style lang="scss" scoped></style>
