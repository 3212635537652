<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3 class="mb-4">{{ $t("COMMON.COMMENTS") }}</h3>
        </div>
        <div class="col-6 text-right">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            @click="openCreateCommentModal"
          >
            <!--<span class="btn-inner--icon">
              <i class="far fa-money"></i>
            </span>-->
            <span class="btn-inner--text">
              {{ $t("COMMON.NEW_COMMENT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>
    <div class="all-infos">
      <div class="w-100">
        <span class="resize-loading" v-if="loading">
          <span class="loader"></span>
        </span>
        <comments-list
          :comments="comments"
          @onReply="onReply"
          :can-load-more="canLoadMore"
          @onLoadMore="onLoadMore"
        />
        <div class="estimate-comment-add" v-if="canAddComment && opened">
          <button
                type="button"
                class="close text-right"
                aria-label="Close"
                @click.prevent="closeAddComment"
              >
                <i class="fal fa-times"></i>
              </button>
          <base-input
            type="textarea"
            :label="$t('COMMON.NEW_COMMENT')"
            :placeholder="$t('COMMON.NEW_COMMENT')"
            :disabled="!canComment"
          >
            <div
              class="bg-lighter mt-2 p-3 border-left-4px text-sm"
              v-if="comment.replyTo"
            >
              <button
                type="button"
                class="close text-right"
                aria-label="Close"
                @click.prevent="removeReply"
              >
                <i class="fal fa-times"></i>
              </button>
              {{ comment.replyTo.short_comment }}
            </div>
            <div class="form-wrapper full">
              <attachements-selector
                :label="$t('RECLAMATIONS.ATTACHEMENTS')"
                :defaultAttachements="comment.attachements"
                ressource_name="comments"
                :ressource_id="comment.id ? comment.id : 0"
                field="attachements"
                @attachementsChanged="
                  (attachements_urls) => {
                    comment.attachements = attachements_urls;
                    onFormChanged();
                  }
                "
              />
            </div>
            <html-editor v-model="comment.comment" :disabled="!canComment" />
          </base-input>
          <div class="estimate-comment-add-buttons">
            <base-button
              type="button"
              class="btn btn-sm add"
              @click.prevent="submitComment"
              :disabled="!commentTextLength || !canComment"
            >
              {{ $t("COMMON.SUBMIT") }}
              <i class="fal fa-arrow-right"></i>
            </base-button>
            <base-button
              @click.prevent="reset"
              type="button"
              class="btn btn-sm cancel"
            >
              {{ $t("COMMON.DELETE") }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import CommentsList from "./partials/CommentsList.vue";
import defaultComment from "./defaultComment";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import AttachementsSelector from "@/components/AttachementsSelector.vue";

export default {
  name: "comments-component",

  components: {
    HtmlEditor,
    CommentsList,
    AttachementsSelector,
  },

  props: {
    object: {
      type: Object,
      required: true,
      description: "Comments linked object",
    },
    canAddComment: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    let comment = cloneDeep({
      ...defaultComment,
      object: this.object,
      organization: this.object.organization,
    });

    comment = this.$fillUserOrganizationData(comment);

    return {
      comment,
      comments: [],
      loading: false,
      perPage: 5,
      opened: false,
    };
  },

  computed: {
    ...mapGetters("comments", { total: "listTotal" }),
    canLoadMore: function () {
      return this.total > this.comments.length;
    },
    commentTextLength: function () {
      return new DOMParser().parseFromString(this.comment.comment, "text/html")
        .body.textContent.length;
    },
    canComment: function () {
      return this.$currentUserCan(this.$permissions.PERM_CREATE_COMMENTS);
    },
  },

  async created() {
    await this.getList();
  },

  methods: {
    openCreateCommentModal(){
      this.opened = true;
    },
    async getList() {
      this.loading = true;
      try {
        let params = {
          sort: "-created_at",
          filter: {
            // replyTo: null,
          },
          include: "organization,creator,replyTo,object",
        };

        if (this.comment.organization?.id) {
          params = {
            ...params,
            page: {
              size: this.perPage,
            },
            filter: {
              ...params.filter,
              organization: this.comment.organization?.id,
            },
          };
        }
        if (this.object) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              objectType: this.object.type,
              objectId: this.object.id,
            },
          };
        }

        await this.$store.dispatch("comments/list", params);
        this.comments = this.$store.getters["comments/list"];

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async submitComment() {
      // this.$emit("onSubmitComment", this.comment);

      this.loading = true;

      try {

        await this.$store.dispatch("comments/add", this.comment);

        await this.getList();

        this.comment = { ...this.comment, comment: "", replyTo: null };

        this.loading = false;

        this.$emit("onCommentsChanged");

        this.$nextTick(function () {
          this.$emit("close");
        });
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    closeAddComment(){
      this.opened = false;
    },
    onReply(comment) {
      this.comment = { ...this.comment, replyTo: comment };
      this.opened = true;
    },
    removeReply() {
      this.comment = { ...this.comment, replyTo: null };
    },
    onLoadMore() {
      this.perPage = this.perPage + 5;
    },
    reset() {
      this.comment.comment = "";
      // this.$emit("close");
    },
  },

  watch: {
    async perPage() {
      await this.getList();
    },
  },
};
</script>

<style lang="scss">
.border-left-4px {
  border-left-style: solid !important;
  border-left-width: 4px !important;
}
.border-color-primary {
  border-color: var(--primary-color);
}
.elite-content .estimate-comment-add-buttons button, .elite-content .estimate-comment-add-buttons button i{
  color: #FFF !important;
}
.elite-content .estimate-comment-add-buttons button.cancel{
  color: #000 !important;
}
</style>
